import React from 'react'
import tw, { css } from 'twin.macro'

export default function Dart({ textColor, height }) {
  const dartCircle = {
    navy: css`
      fill: none;
      stroke: #283538;
    `,
    white: css`
      fill: none;
      stroke: #fff;
    `,
  }
  const dart = {
    navy: css`
      fill: #283538;
    `,
    white: css`
      fill: #fff;
    `,
  }

  const size = {
    sm: css`
      ${tw`my-10`} height:70px;
      width: 70px;
      margin-right: 2vw;
    `,
    md: css`
      ${tw`my-20`} height:100px;
      width: 100px;
      margin-right: 3vw;
    `,
    lg: css`
      ${tw`py-40`} height:100px;
      width: 100px;
      margin-right: 3vw;
    `,
    full: css`
      ${tw`my-20`} height:100px;
      width: 100px;
      margin-right: 3vw;
    `,
  }

  return (
    <svg
      css={[
        size[height],
        css`
          ${tw`absolute right-0 bottom-0`}

          @media (max-width: 768px) {
            height: 40px;
            width: 40px;
            margin-bottom: 10px;
          }
        `,
      ]}
      data-name="Gå til pil"
      xmlns="http://www.w3.org/2000/svg"
      width="131"
      height="131"
      viewBox="0 0 131 131"
    >
      <path
        id="arrow-right"
        d="M65.08,28.686l-7.8-9.149a2.729,2.729,0,0,0-4.158,3.535l5.51,6.654H2.807a2.807,2.807,0,1,0,0,5.614H58.739l-5.51,6.654a2.585,2.585,0,0,0,.312,3.847,2.125,2.125,0,0,0,1.663.52,2.46,2.46,0,0,0,2.183-1.144l7.8-9.149C67.056,34.3,67.056,30.973,65.08,28.686Z"
        transform="translate(32.556 33.384)"
        css={dart[textColor]}
      />
      <g
        id="Ellipse_6"
        data-name="Ellipse 6"
        strokeWidth="5"
        css={dartCircle[textColor]}
      >
        <circle cx="65.5" cy="65.5" r="65.5" stroke="none" />
        <circle cx="65.5" cy="65.5" r="63" fill="none" />
      </g>
    </svg>
  )
}
