import React from 'react'
import tw, { css } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ReadMoreButton from '../ReadMoreButton'
import Dart from '../Svgs/Dart'

const HasLink = (props) => {
  if (props.linkTo.length)
    return (
      <Link
        to={'/' + props.linkTo[0].uri}
        css={[
          tw`block hover:relative hover:z-10 hover:scale-105 transform transition ease-in duration-300`,
          props.backgroundImage && tw`hover:shadow-lg `,
        ]}
      >
        {props.children}
        <Dart textColor={props.textColor} height={props.height} />
      </Link>
    )
  return <>{props.children}</>
}

export default function TextImage(props) {
  const {
    height,
    width,
    text,
    textColor,
    backgroundColor,
    linkTo,
    marginTop,
    marginBottom,
    topBottomBorder,
    readMoreHide,
    hideMobile,
    backgroundCover,
  } = props.content

  const backgroundImage = getImage(
    props.content?.backgroundImage[0]?.localFile?.childImageSharp
      ?.gatsbyImageData
  )
  const paddingValues = {
    sm: tw`py-10 justify-center`,
    md: tw`py-20 justify-center`,
    lg: tw`py-40 justify-center`,
    full: [
      tw`justify-end py-20`,
      css`
        min-height: 80vh;
        @media (max-width: 768px) {
          min-height: 50vh;
        }
      `,
    ],
  }

  return (
    <section
      css={[
        tw`relative`,
        hideMobile && tw`hidden md:block`,
        backgroundColor === 'bg-navy' && tw`bg-navy`,
        backgroundColor === 'bg-stm' && tw`bg-stm`,
        marginTop === 'sm' && tw`mb-2 md:mt-6`,
        marginTop === 'md' && tw`mb-6 md:mt-12`,
        marginTop === 'lg' && tw`mb-6 md:mt-24`,
        marginBottom === 'sm' && tw`mb-2 md:mb-6`,
        marginBottom === 'md' && tw`mb-6 md:mb-12`,
        marginBottom === 'lg' && tw`mb-6 md:mb-24`,
        width === 'contained' && tw`container md:px-10 px-6`,
      ]}
      className={`textImage ${width === 'space' && 'mm-mx-dynamic'} ${
        readMoreHide && 'readMoreHide'
      }`}
    >
      <HasLink
        linkTo={linkTo}
        backgroundImage={backgroundImage}
        height={height}
        textColor={textColor}
      >
        <div
          css={tw`relative overflow-hidden`}
          className={topBottomBorder && 'divide'}
        >
          {backgroundImage && (
            <GatsbyImage
              image={backgroundImage}
              alt={props.content.backgroundImage[0]?.title}
              imgStyle={backgroundCover && { width: 'auto', left: 'auto' }}
              objectFit={backgroundCover && 'scale-down'}
              css={tw`absolute w-full h-full`}
            />
          )}
          <div
            css={[
              paddingValues[height],
              textColor === 'white' && tw`text-white`,
              textColor === 'navy' && tw`text-navy`,
              tw`relative z-10 flex flex-col`,
            ]}
            className={`${
              (width === 'full' || backgroundImage) && 'mm-mx-dynamic'
            }`}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      </HasLink>
      <ReadMoreButton text="Læs mere" />
    </section>
  )
}
